/* accordion */
$(function(){
    var accordion = $('.accordion');
    var title = accordion.find('.accordion-head');
    var content = accordion.find('.accordion-content');

    title.click(function(event){
        event.preventDefault();
        $(this).parents('.accordion').toggleClass('__active');
        $(this).siblings().slideToggle();
    });
});
