/* ScrollTo */
(function(){
    $.fn.scrollto = function(options) {
        var settings = $.extend({
            trigger: '#ScrollTo',
            scroll_to: '.wrapper',
            speed: 1000,
        }, options);
        $(settings.trigger).click(function(event) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: $(settings.scroll_to).offset().top,
            }, settings.speed);
        });
    }
})();
$('#ScrollTo').scrollto({
    trigger: '#ScrollTo',
    scroll_to: '.wrapper',
    speed: 1000,
});
$('#scroll-to-content').scrollto({
    trigger: '#scroll-to-content',
    scroll_to: '#content',
    speed: 1000,
});