/* modal */
$(function(){
    var trigger = $('.ModalTrigger');
    var modal = $('.modalblock');
    var close = modal.find('.modalblock-close');

    trigger.click(function(event){
        event.preventDefault();
        var modal_id = $(this).data('modal')
        modal.filter('[data-modal="' + modal_id + '"]').addClass('__active');
    });

    close.click(function(){
        modal.removeClass('__active');
    });

    $(window).click(function(event){
        if(event.target.className == "modalblock __active"){
            modal.removeClass('__active');
        }
    });

    $(document).keyup(function(e) {
        if (e.keyCode == 27) {
            $('.modablock.__active').removeClass('__active');
        }
    });
});
