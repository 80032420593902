/*share*/
$(function(){
    // var share_toggle = $('.shareitem-trigger');
    var share_toggle = $('.shareitem');
    share_toggle.hover(function(){
        $(this).find('.shareitem-icons').toggleClass('__active');
      },function() {
        $(this).find('.shareitem-icons').removeClass('__active');
      }
    );
});

/* share social */
$(function(){
    var toggle = $('.socialshare-title');
    var shareicons = $('.socialshare-socialset');

    toggle.click(function(event){
        event.preventDefault();
        $(this).siblings('.socialshare-socialset').toggleClass('__active');
    });
});
