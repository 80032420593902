// animated form fields
$(document).ready(function(){
    $(document.body).on('focusin', '.field_wrap.__animated .input_wrap', function(){
            var input = $(this).children("input, textarea");
            if (!input.val() && !$(this).parent().hasClass("__active")) {
                $(this).parent().addClass("__active");
            }
        });
        $(document.body).on('focusout', '.field_wrap.__animated .input_wrap', function() {
            var input = $(this).children("input, textarea");
            if (!input.val()) {
                $(this).parent().removeClass("__active");
            }
        });

        $(document.body).on('focusin', '.animatedfield .field_wrap .input_wrap', function(){
            var input = $(this).children("input, textarea");
            if (!input.val() && !$(this).parent().hasClass("__active")) {
                $(this).parent().addClass("__active");
            }
        });
        $(document.body).on('focusout', '.animatedfield .field_wrap .input_wrap', function() {
            var input = $(this).children("input, textarea");
            if (!input.val()) {
                $(this).parent().removeClass("__active");
            }
        });
});
