require('./modules/accordion.js')
require('./modules/animation.js')
require('./modules/formInputs.js')
require('./modules/menu.js')
require('./modules/modal.js')
require('./modules/newsletter.js')
require('./modules/scrollTo.js')
require('./modules/searchToggle.js')
require('./modules/shareToggle.js')
require('./modules/sidemenu.js')
require('./modules/stickyheader.js')
require('./modules/tabs.js')