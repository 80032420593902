/* radiobuttons */
$(document).on('change', '.__radiobuttons input', function(){
    if ($(this).is(':checked')) {
        $(this).closest('.__radiobuttons').find('.input_wrap > label').removeClass('__checked')
        $(this).parent().addClass('__checked');
    }
});
$('.__radiobuttons input:checked').parent().addClass('__checked');


/* checkboxes */
$(document).on('change', '.__checkboxes input', function(){
    if ($(this).is(':checked')) {
        $(this).parent().addClass('__checked');
    } else {
        $(this).parent().removeClass('__checked');
    }
});
$('.__checkboxes input:checked').parent().addClass('__checked');

