
/* Main Menu */
$(function(){

    var body =  $('body');
    var nav = $('.nav');
    var burger =  $('.burger');
    var nav_trigger =  $('.nav').find('ul li');
    var nav_align = 'right';
    var break_point =  992;
    var browser_width = window.innerWidth;
    var nav_width = 300;
    var nav_window = $('<div class="navwindow"></div>');
    body.append(nav_window);
    var nav_wrap = $('.navwrap');


    nav_window.click(function(event){
        nav_reset();
    });

    //burger click
    burger.click(function(event){
        event.preventDefault();
        $(this).toggleClass('__active');
        if(body.hasClass('__mobile')){
            nav_reset();
        }else{
            nav_launch();
        };
    });

    /*nav reset*/
    var nav_reset = function nav_reset(){
        burger.removeClass('__active');
        nav_window.hide();
        body.removeClass('__mobile');
        nav_wrap.removeClass('__active');
        nav_wrap.find('ul').removeClass('__active');
    };

    /*nav launch*/
    var nav_launch = function nav_launch(){
        nav_window.show();
        body.addClass('__mobile');
        nav_wrap.addClass('__active');
    };

    if(browser_width < break_point){
        nav_trigger.click(function(event){
            event.stopPropagation();
            if($(this).children('ul').length > 0){
                event.preventDefault();
                $(this).parents('ul').removeClass('__active');
                $(this).children('ul').addClass('__active');
            };
        });
    };

    var nav_ul = $('.nav ul li > ul');
    var view_button = $('<li class="viewall"><a href="#">View</a></li>');
    var back_button = $('<li class="backto"><a href="#">Back</a></li>');

    if(browser_width < break_point){
        nav_ul.prepend(view_button);
        nav_ul.prepend(back_button);
    };

    var back_menu = $('.backto');
    back_menu.click(function(event){
        event.preventDefault();
        event.stopPropagation();
        $(this).parent('ul').removeClass('__active');
        $(this).parents('li').parent('ul').addClass('__active');
    });

    var view_all = $('.viewall');
    view_all.each(function(){
        var parent_url = $(this).closest('ul').siblings().attr('href');
        var parent_title = $(this).closest('ul').siblings().html();
        $(this).find('a').attr('href', parent_url);
        $(this).find('a').html('View ' + parent_title);
    });

    view_all.click(function(event){
        event.stopPropagation();
    });

});
