/* tabpanel */
// $(function(){
//     var tab = $('.tabpanel-tab');
//     var panel = $('.tabpanel-panel');
//
//     tab.eq(0).addClass('__active');
//     panel.eq(0).addClass('__active');
//
//     tab.click(function(event){
//         event.preventDefault();
//         tab.removeClass('__active');
//         $(this).addClass('__active');
//         var data_set = $(this).data('set');
//         panel.removeClass('__active');
//         panel.filter('[data-set="' + data_set + '"]').addClass('__active');
//     });
// });

/* tabpanel */
$(function(){
    $('.tabpanel').each(function(){
        var tab = $(this).find('.tabpanel-tab');
        var panel = $(this).find('.tabpanel-panel');

        tab.eq(0).addClass('__active');
        panel.eq(0).addClass('__active');

        tab.click(function(event){
            event.preventDefault();
            tab.removeClass('__active');
            $(this).addClass('__active');
            var data_set = $(this).data('set');
            panel.removeClass('__active');
            panel.filter('[data-set="' + data_set + '"]').addClass('__active');
        });
    });    
});
