/* searchtoggle */
$(function(){
    var search_toggle = $('.searchtoggle');
    var wrapper = $('.wrapper');

    search_toggle.click(function(event){
        event.preventDefault();
        $(this).toggleClass('__active');
        wrapper.toggleClass('__searchactive');
    });
});
